import React from "react";
import "./SingleCard.css";

export default function SingleCard({ card, handleChoice, flipped, disabled }) {
  const handleClick = () => {
    handleChoice(card);
  };

  return (
    <div className="card-wrapper">
      <div className={flipped ? "flipped" : ""}>
        <div
          className={`card front d-flex align-items-center justify-content-around`}
        >
          {card.number}
        </div>
        <div
          className={`card d-flex align-items-center justify-content-around d-flex align-items-center justify-content-around ${
            card.number ? "back" : null
          } ${card.gameOver && card.number ? "bg-warning" : null}`}
          onClick={card.number ? handleClick : null}
        >
          {card.visible ? card.number : null}
        </div>
      </div>
    </div>
  );
}
