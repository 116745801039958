import { useEffect, useState } from "react";
import "./App.css";
import SingleCard from "./components/SingleCard";

const settings = {
  grid: 24,
};

function App() {
  const [cards, setCards] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [turns, setTurns] = useState(1);
  const [currentNumber, setCurrentNumber] = useState(1);

  const [timeoutID, setTimeoutID] = useState(null);

  const [highscore, setHighscore] = useState(null);

  // shuffle cards
  const suffleCards = () => {
    setDisabled(true);
    const suffledCards = [...Array(settings.grid)].map((card) => ({
      id: Math.random(),
      visible: true,
    }));

    const numbers = [...Array(turns)];
    numbers.forEach((element, index) => {
      var randomIndex = Math.floor(Math.random() * suffledCards.length);
      while (suffledCards[randomIndex].number != null) {
        randomIndex = Math.floor(Math.random() * suffledCards.length);
      }

      suffledCards[randomIndex].number = index + 1;
    });

    setCards(suffledCards);
    setCurrentNumber(1);

    setTimeoutID(
      setTimeout(() => {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            return { ...card, visible: false };
          });
        });
        setDisabled(false);
      }, 1000 * turns)
    );
  };

  // handle a choice
  const handleChoice = (card) => {
    clearTimeout(timeoutID);

    setCards((prevCards) => {
      return prevCards.map((card) => {
        return { ...card, visible: false };
      });
    });

    if (card.number === currentNumber) {
      setCurrentNumber(currentNumber + 1);

      setCards((prevCards) => {
        return prevCards.map((card) => {
          if (card.number === currentNumber) {
            return { ...card, matched: true };
          }
          return card;
        });
      });
    } else {
      setCards((prevCards) => {
        return prevCards.map((card) => {
          return { ...card, visible: true, gameOver: true };
        });
      });

      setTimeout(() => {
        // Update hightscore
        if (turns > highscore) {
          localStorage.setItem("highscore", turns);
        }

        // reset game
        setTurns(1);
      }, 2000);
    }
  };

  useEffect(() => {
    suffleCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turns]);

  // reset game (after finished)
  useEffect(() => {
    if (localStorage.getItem("highscore")) {
      setHighscore(localStorage.getItem("highscore"));
    }

    if (cards.length > 0) {
      const gameFinished = cards
        .filter((card) => card.number)
        .every((card) => card.matched);
      if (gameFinished) {
        setTimeout(() => {
          // next level
          setTurns(turns + 1);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  return (
    <>
      <div className="container-md">
        <div
          style={{
            textAlign: "center",
            width: "100%",
            display: "inline-block",
          }}
        >
          <h2 className="my-5">
            🏋️🧠 Brainpump 🧠🏋️<br></br>
            <small>
              Level <b>{turns}</b>
              {highscore ? (
                <small>
                  <br></br>Highscore {highscore}
                </small>
              ) : null}
            </small>
          </h2>
          <div className="d-flex align-items-center">
            <div className="card-grid m-0 px-5 mx-auto">
              {cards.map((card) => (
                <SingleCard
                  key={card.id}
                  card={card}
                  handleChoice={handleChoice}
                  flipped={card.matched}
                  disabled={disabled}
                ></SingleCard>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
